h1,h2,h3,h4,h5,h6 {
    color:black;
}

tr{
    line-height:13px !important;
    vertical-align: middle;
}

.beer-image{
    width:auto;
    max-height:50px !important;
}
.beer-logo-scroll{
    height: 305px;
}
.beer-logo-scroll img{
    width:auto;
    max-height:310px;
}

.small-listing{
    width:auto;
    max-height:40px !important;
}

.page-link {
    cursor:pointer;
}

.beer-info img{
    display:flex;
    max-height:250px;
    width:auto;
    margin:auto;
    display:block;
}

.beer-listing-header{
    color:white !important;
}

.beer-info{
    white-space: pre-wrap;
}

.beer-info h4{
    text-align:center !important;
}