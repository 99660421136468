h1,h2,h3,h4,h5,h6 {
    color:black;
}

.brewery-logo-scroll {
    padding:10px 0;
    margin: 10px 0;
}

.brewery-logo-scroll img {
    max-height: 175px;
    justify-content: center;
}

div.brewery-logo-scroll{
    height:175px;
    width:auto;
    display:flex;
    justify-content: center;
    align-items: center;
}

tr{
    line-height:13px !important;
}

.brewery-list{
    display:flex;
    flex-wrap: wrap;
}

.brewery-card img {
    width:auto;
    max-height:85px;
}

.brewery-card{
    min-height:300px !important;
    width:348px !important;
    margin-right: 10px !important;
} 

.brewery-card .card-header{
    font-size: 30px;
}

.brewery-card .card-footer {
    width:100%;
    margin:0;
}

.brewery-info img.logo{
    max-height:150px;
    width:auto;
}

.brewery-info{
    white-space: pre-wrap;
}
 
iframe {
    border: 2px solid #fef4cf;
    box-shadow: 2px 4px 4px rgba(157, 62, 39, .3);    
 }