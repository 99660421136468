/* Author: Christian M RApp | CMR Web studio
   Date: 7/18/23
   (c) 2023 Christian M Rapp | All Rights Reserved	
   This stylesheet contains the GLOBAL styles for the app
*/

*{
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

/* WEBFONTS */
@font-face {
  font-family: 'Roadkill';
  src: url('../public/fonts/roadkillw00-regular-webfont.woff') format('woff2'),
       url('../public/fonts/roadkillw00-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

/* TYPOGRAPHY */
h1,h2,h3,h4{
  font-family: Roadkill, system-ui, 'Segoe UI', Roboto, Oxygen, Ubuntu, 'Open Sans', 'Helvetica Neue', sans-serif; 
  color:#a5341b;
}

h1 {font-size: 5.5em;}
h2 { font-size:4.5em;}
h3 {font-size: 3.5em;}
h4 {font-size: 3em;}

p {
   color:black;
}

.hero-image{
	max-width:1920px;
	margin-left: -1px;
}

.page-image{
	border-radius:2%;
}

.carousel-indicators, .carousel-control-prev-icon, .carousel-control-next-icon { 
	display:none !important; 
}

th {
    background-color:#b76a55 !important;
    color: #fef4cf !important;
    font-family:Roadkill;
    font-size:32px;
    font-weight:100;
    text-shadow: 2px 1px #000000;
}

/* STYLE SCROLLBARS */
/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #fef4cf #212529;
  }
  
  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 15px;
  }
  
  *::-webkit-scrollbar-track {
    background: #212529;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #fef4cf;
    border-radius: 6px;
    border: 3px solid #212529;
  }

  /* Global Modal and Card Styling */
  .modal-header, .modal-footer, .card-header, .card-footer{
  background-color: #9d3e27 !important;
  background-image: url(../public/img/palm-tile2.png);
  background-repeat: repeat-x;
  color: #fef4cf;
}

.card-text, .modal-body{
  text-align: center;
}

.card{
  margin-right:30px;
  min-height:325px;
}

.card-header{
  font-family:Roadkill;
  font-size:40px ;
  text-shadow: 2px 2px #000000;
}

.modal-title{
  font-family:Roadkill;
    font-size:50px !important;
    font-weight:100;
    text-shadow: 2px 1px #000000;
}

.modal-body h5{
  color:black !important;
}

.hidden{
  display:none;
}

.visible {
  display:block;
}

/* FORM STYLING */
input {
  margin-bottom:5px;
  color:black;
  border-color: #a5341b !important;
}

  